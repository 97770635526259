/* eslint-disable promise/catch-or-return */
import yandexMapsGeocodeApiCall from '@hh.ru/analytics-js-events/build/xhh/common/yandex_maps/geocode';

import utils from 'Modules/Maps/Utils';

const RESULTS_COUNT = 20;
export default {
    geocode(request, options) {
        const promise = new window.ymaps.vow.defer();
        const results = options.results || RESULTS_COUNT;
        const response = {
            metaData: {
                geocoder: {
                    request,
                    found: results,
                    results,
                    skip: options.skip || 0,
                },
            },
            geoObjects: new window.ymaps.GeoObjectCollection(),
        };

        window.ymaps.geocode(request, { results }).then((result) => {
            yandexMapsGeocodeApiCall({ hhtmSourceLabel: 'address-modal-search-address' });
            result.geoObjects.each((item) => {
                const geoObject = utils.convertGeoObject(item);
                if (
                    geoObject.LocalityName ||
                    geoObject.DependentLocalityName ||
                    geoObject.AdministrativeAreaName ||
                    geoObject.SubAdministrativeAreaName
                ) {
                    response.geoObjects.add(item);
                }
            });
            promise.resolve(response);
        });

        return promise.promise();
    },
};
