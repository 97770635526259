import { ChangeEventHandler, MouseEventHandler, PropsWithChildren, FC } from 'react';

import { CameraOutlinedSize16, PenSquareOutlinedSize16, TrashOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Text, { TextSize } from 'bloko/blocks/text';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';

import styles from './sidebar-item.less';

interface SidebarItemProps {
    number?: number;
    onUpload?: ChangeEventHandler<HTMLInputElement>;
    onEdit?: MouseEventHandler<HTMLButtonElement>;
    onRemove?: MouseEventHandler<HTMLButtonElement>;
}

const SidebarItem: FC<PropsWithChildren<SidebarItemProps>> = ({ children, number, onUpload, onEdit, onRemove }) => (
    <div className={styles.widgetGalleryItem}>
        <div className={styles.widgetGalleryItemNumber}>
            <Text size={TextSize.Small}>{number}</Text>
        </div>
        <div className={styles.widgetGalleryItemImage}>{children}</div>
        <div className={styles.widgetGalleryItemActions}>
            <label>
                <span className={styles.widgetGalleryItemButton}>
                    <BlokoIconReplaceContainer>
                        <CameraOutlinedSize16 initialColor="secondary" />
                    </BlokoIconReplaceContainer>
                    <input data-qa="gallery-widget-file-input" type="file" className="g-hidden" onChange={onUpload} />
                </span>
            </label>
            <button data-qa={'gallery-widget-button-edit'} className={styles.widgetGalleryItemButton} onClick={onEdit}>
                <BlokoIconReplaceContainer>
                    <PenSquareOutlinedSize16 initialColor="secondary" />
                </BlokoIconReplaceContainer>
            </button>
            <button
                data-qa={'gallery-widget-button-remove'}
                className={styles.widgetGalleryItemButton}
                onClick={onRemove}
            >
                <BlokoIconReplaceContainer>
                    <TrashOutlinedSize16 initialColor="secondary" />
                </BlokoIconReplaceContainer>
            </button>
        </div>
    </div>
);

export default SidebarItem;
