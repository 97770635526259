import yandexMapsGeolocationApiCall from '@hh.ru/analytics-js-events/build/xhh/common/yandex_maps/geolocation';

import { Coordinates, GeoObjectsContext, YMaps, YMapsInstance } from 'Modules/Maps/maps.types';
import { Coordinate, Metro, MetroStation } from 'src/components/AddressSuggest/types';
import { VacancyAddress } from 'src/models/vacancyView.types';

export const prepareCoordinates = ({ lat = 0, lng = 0 }: Partial<Coordinate>): Coordinate => ({ lat, lng });

export const prepareMetroItem = ({ id, name, line, lat, lng, ...other }: MetroStation): Metro => ({
    id,
    cityId: other['@cityId'],
    text: name,
    name,
    line,
    lat,
    lng,
});

const prepareAddressParams = (coordinates: Coordinates, zoom: number): VacancyAddress => {
    const [lat, lng] = coordinates;
    return {
        mapData: {
            points: {
                center: { lat, lng, zoom },
            },
        },
    };
};

export const createYandexMaps = async (
    element: HTMLElement,
    center: Coordinates,
    zoom: number,
    provider?: 'auto' | 'yandex',
    hhtmSourceLabel?: string
): Promise<{
    ymaps: YMaps | null;
    mapInstance: YMapsInstance | null;
    userLocationGeoObject: GeoObjectsContext | null;
}> => {
    let ymaps = null;
    let mapInstance = null;
    let userLocationGeoObject = null;
    if (!process.env.SSR) {
        try {
            const showMapModule = await import(/* webpackMode: "eager" */ 'Modules/Maps/showMap');
            const result = await showMapModule.default(
                element,
                {
                    address: prepareAddressParams(center, zoom),
                },
                undefined,
                hhtmSourceLabel
            );
            if (provider) {
                let boundsResult;
                try {
                    boundsResult = await result?.ymaps.geolocation.get({ provider });
                    yandexMapsGeolocationApiCall({ hhtmSourceLabel });
                } catch (e) {
                    console.error('Failed to install bounds', e);
                }
                userLocationGeoObject = boundsResult?.geoObjects.get(0) || null;
                const bounds = userLocationGeoObject?.properties.get('boundedBy');
                if (bounds) {
                    result?.mapInstance.setBounds(bounds);
                }
            }
            ymaps = result?.ymaps || null;
            mapInstance = result?.mapInstance || null;
        } catch (error) {
            console.error('Yandex Map Instance Creating Error: ', error);
        }
    }
    return { ymaps, mapInstance, userLocationGeoObject };
};
/**
 * Обрезает текст до заданной длины и добавляет многоточие, если текст превышает эту длину.
 *
 * @param text - Текст, который необходимо обрезать.
 * @param length - Максимальная длина текста.
 * @param isMobile - Флаг, определяющий, следует ли обрезать текст, даже если он короче заданной длины.
 * @param ellipsis - Многоточие, которое нужно добавить, если текст превышает заданную длину.
 * @return Вырезанный текст с многоточием, если необходимо.
 */
export const cutMetro = (text: string | undefined, length: number, isMobile: boolean, ellipsis = '...'): string => {
    if (!text) {
        return '';
    } else if (text.length <= length || isMobile) {
        return text;
    }

    return `${text.substring(0, length)}${ellipsis}`;
};
