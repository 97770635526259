import { useMemo } from 'react';

import { DataProviderResult, DataProvider } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';
import { buildDataProvider, getAutosuggestItems, SuggestUrl } from 'src/utils/suggest/buildDataProvider';

import { Metro } from 'src/components/AddressSuggest/types';

const formatter = (items: Metro[] | null): DataProviderResult<Metro> => {
    if (!items) {
        return [];
    }

    return items.map((item) => ({
        type: 'cells',
        items: [
            {
                data: item,
                value: item?.name ?? '',
            },
        ],
    }));
};

const useMetroDataProvider = (cityId: string, onSaveFirstMetro: (data: Metro[]) => void): DataProvider<Metro> => {
    const lang = useSelector((state) => state.langs[0]);
    const dynamicUrlPart = `metro_station_${lang}_${cityId}`;

    const searchProvider = useMemo(
        () =>
            buildDataProvider(SuggestUrl.Auto, dynamicUrlPart, formatter, getAutosuggestItems, {
                minCharsCount: 2,
                onBlurSaveFirstValue: onSaveFirstMetro,
            }),
        [dynamicUrlPart, onSaveFirstMetro]
    );

    return searchProvider;
};

export default useMetroDataProvider;
