import { FC, memo } from 'react';

import { Card, Cell, CellText, VSpacingContainer, Loader } from '@hh.ru/magritte-ui';

import { AddressState, YandexCity } from 'src/components/AddressSuggest/types';

import styles from './add-address-modal.less';

interface AddressSearchResultsProps {
    isLoading: boolean;
    addressState: AddressState;
    cities: YandexCity[];
    onResultClick: (city: YandexCity) => void;
}

const AddressSearchResults: FC<AddressSearchResultsProps> = ({ isLoading, addressState, cities, onResultClick }) => {
    if (addressState !== AddressState.Search) {
        return null;
    }

    if (isLoading) {
        return (
            <div className={styles.loadingWrapper}>
                <Loader initial="accent" size={24} />
            </div>
        );
    }

    return (
        <VSpacingContainer default={12}>
            {cities.map((city, index) => (
                <Card
                    key={`city${index}`}
                    stretched
                    borderWidth="default"
                    padding={12}
                    borderRadius={12}
                    data-qa="address-edit-search-result"
                >
                    <Cell onClick={() => onResultClick(city)}>
                        <CellText type="title">{city.address}</CellText>
                        <CellText type="subtitle">{city.city}</CellText>
                    </Cell>
                </Card>
            ))}
        </VSpacingContainer>
    );
};

export default memo(AddressSearchResults);
