import Loading, { LoadingScale } from 'bloko/blocks/loading';

const Loader = ({ loading }) => {
    return loading ? (
        <div className="editor-loader">
            <Loading scale={LoadingScale.Medium} />
        </div>
    ) : null;
};

export default Loader;
