import { FC, PropsWithChildren, useRef } from 'react';
import { Transition } from 'react-transition-group';

const ErrorComponent: FC<PropsWithChildren<{ show: boolean }>> = ({ children, show }) => {
    const errorRef = useRef(null);

    return (
        <Transition in={show} timeout={100} nodeRef={errorRef} mountOnEnter unmountOnExit appear>
            {(state) => (
                <div
                    className={`validation-error validation-error_${state}`}
                    data-qa="createvacancy__error"
                    ref={errorRef}
                >
                    {children}
                </div>
            )}
        </Transition>
    );
};

export default ErrorComponent;
