import { ForwardedRef, forwardRef, ForwardRefRenderFunction, useCallback } from 'react';

import {
    Button,
    DataProvider,
    Placeholder,
    SearchInput,
    Suggest,
    useBreakpoint,
    VSpacing,
    CellText,
    Cell,
} from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SuggestResponse } from 'Modules/Maps/maps.types';
import Form from 'src/components/Form';
import translation from 'src/components/translation';

import styles from './add-address-modal.less';

const TrlKeys = {
    placeholder: 'employer.address.edit.search.placeholder',
    editSearch: 'employer.address.edit.search',
    errorTitle: 'employer.address.suggest.errors.placeholder',
    resetButton: 'employer.address.suggest.errors.button',
    errorDescription: 'employer.address.suggest.errors.description',
    label: 'employer.address.suggest.label',
};

interface AddressSearchFormProps {
    fwdRef?: ForwardedRef<HTMLDivElement>;
    value: string;
    setValue: (searchValue: string) => void;
    onSubmit: (value: string) => void;
    dataProvider: DataProvider<SuggestResponse>;
    suggestEnabled: boolean;
    loading: boolean;
}

const AddressSearchFormRaw: TranslatedComponent<AddressSearchFormProps> = ({
    fwdRef,
    trls,
    value,
    dataProvider,
    setValue,
    onSubmit,
    loading,
    suggestEnabled,
}) => {
    const { isGtS } = useBreakpoint();
    const searchRef = useCallback((node: HTMLDivElement | null) => isGtS && node?.focus(), [isGtS]);

    return (
        <div ref={fwdRef}>
            <Form
                className={styles.addressModalSearch}
                name="add-address-form"
                onSubmit={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onSubmit(value);
                }}
            >
                {suggestEnabled ? (
                    <Suggest
                        input={{
                            component: SearchInput,
                            props: {
                                value,
                                clearable: true,
                                ref: searchRef,
                                placeholder: trls[TrlKeys.placeholder],
                                'data-qa': 'address-edit-search-field',
                                disabled: loading,
                            },
                        }}
                        navigationBarProps={{
                            title: trls[TrlKeys.label],
                        }}
                        inputValue={value}
                        onChange={(value, isValueFromList) => {
                            setValue(value);

                            if (isValueFromList) {
                                onSubmit(value);
                            }
                        }}
                        dataProvider={dataProvider}
                        errorPlaceholder={
                            <Placeholder
                                title={trls[TrlKeys.errorTitle]}
                                description={trls[TrlKeys.errorDescription]}
                            />
                        }
                        trls={{
                            resetButton: trls[TrlKeys.resetButton],
                        }}
                        renderItem={({ data: item }) => {
                            if (!item) {
                                return null;
                            }
                            return (
                                <Cell>
                                    <CellText>{item.displayName} </CellText>
                                    <CellText type="subtitle">{item.value}</CellText>
                                </Cell>
                            );
                        }}
                    />
                ) : (
                    <SearchInput
                        clearable
                        ref={searchRef}
                        onChange={setValue}
                        value={value}
                        placeholder={trls[TrlKeys.placeholder]}
                        data-qa="address-edit-search-field"
                    />
                )}
                <Button
                    data-qa="address-edit-search-submit"
                    type="submit"
                    mode="secondary"
                    disabled={loading}
                    loading={loading}
                >
                    {trls[TrlKeys.editSearch]}
                </Button>
            </Form>
            <VSpacing default={24} />
        </div>
    );
};

const Translated = translation(AddressSearchFormRaw);
const AddressSearchForm: ForwardRefRenderFunction<HTMLDivElement, AddressSearchFormProps> = (props, ref) => (
    <Translated {...props} fwdRef={ref} />
);

export default forwardRef(AddressSearchForm);
